import { useState, useEffect } from 'react'
import { useDisconnect, useAccount, useEnsAvatar, useEnsName, useSignMessage, useConnect } from 'wagmi'
import { Network, Alchemy } from "alchemy-sdk";
import * as ethers from 'ethers';
import { nanoid } from 'nanoid';
import { isMobile } from 'react-device-detect';

const settings = {
    apiKey: '0XvdV22GUFtEtrBERMy9YBGQ5MKQVT7L',
    network: Network.MATIC_MAINNET,
};

const alchemy = new Alchemy(settings);


export function Profile() {

    const [modalOpen, setModal] = useState(false);
    const [modalProfileOpen, setModalProfile] = useState(false);
    const [copyAddress, setCopyAddress] = useState('');
    const [userNFTs, setUserNFTs] = useState([]);
    const [hashSignature] = useState(localStorage.getItem('impactSign'));

    const { address, connector, isConnected } = useAccount();
    const { data: ensAvatar } = useEnsAvatar({ addressOrName: address })
    const { disconnect } = useDisconnect();
    const { data: ensName } = useEnsName({ address })
    const { connect, connectors, isLoading, pendingConnector } =
        useConnect();

    const getRandomString = (bytes = 15) => {
        const buffer = nanoid(bytes);
        const randomString = buffer.toString('hex');

        return randomString;
    }

    const nftContractAddress = document.getElementById('mint_product_contract') ? document.getElementById('mint_product_contract').value : '0x88C7aa3c07A4cD8ac4b4fD8AfacECbb478AfC831';

    const { data: dataSign, signMessage } = useSignMessage({
        onSuccess(data, variables) {
            // Verify signature when sign message succeeds
            const address_validate = ethers.verifyMessage(variables.message, data);
            if (address_validate === address) {// validation ok
                localStorage.setItem('impactSign', data);
                localStorage.setItem('impactHash', variables.message);
                const eventValidate = new CustomEvent('storageHashValidate');
                window.dispatchEvent(eventValidate);
            }
        },
        onError(error) {
            if (error.action === "signMessage" && error.code === "ACTION_REJECTED") {
                const eventRejected = new CustomEvent('storageHashRejected');
                window.dispatchEvent(eventRejected);
            }
        },
    });

    // Request sign message after connection
    useEffect(() => {
        if (isConnected && address && !isMobile) {
            const eventStored = new CustomEvent('storageHash');
            window.dispatchEvent(eventStored);
            const message = getSignInMessageString();
            signMessage({ message });
        }
    }, [isConnected, address, signMessage]);

    useEffect(() => {

        // Save main address in local storage
        localStorage.setItem('mainAddress', address);
        window.dispatchEvent(new Event('storage'));

        if (address) {
            alchemy.nft.getNftsForOwner(address, {contractAddresses: [nftContractAddress]}).then((data) => {
                setUserNFTs(data.ownedNfts);

                // Save NFTs in local storage
                localStorage.setItem('impactNfts', JSON.stringify(data.ownedNfts));

                window.dispatchEvent(new Event('storage'));
            });

        };

    }, [address, nftContractAddress]);

    const openModalProfile = event => {
        event.preventDefault();
        setModalProfile(true);
        setModal(false);
    }

    const disconnectUser = () => {
        const eventDisconnect = new CustomEvent('impactUserDisconnected');
        window.dispatchEvent(eventDisconnect);
        disconnect();
    };

    // Signatue related stuff
    const getSignInMessageString = () => {
        return "Welcome to Impact Swag Store!\n\nBy signing this message, you accept our Privacy Policy: https://swag.generalmagic.io/privacy-policy/ and prove that you own this wallet address: " + address + ".\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nNonce: " + getRandomString();
    };

    // Request sing in again
    const requestSignAgain = () => {
        if (isConnected && address) {
            const eventStored = new CustomEvent('storageHash');
            window.dispatchEvent(eventStored);
            const message = getSignInMessageString();
            signMessage({ message });
        }
    };

    const openModal = event => {
        event.preventDefault();
        setModal(true);
        setModalProfile(false);
    }

    const closeModal = (event) => {
        if (event.target.className === 'walletModal') {
            setModal(false);
        }
        if (event.target.className === 'walletModalClose') {
            setModal(false);
        }
        if (event.target.className === 'walletModal profileModal') {
            setModal(false);
        }
    }

    const closeModalProfile = (event) => {
        if (event.target.className === 'walletModalClose') {
            setModalProfile(false);
        }
        if (event.target.className === 'walletModal profileModal') {
            setModalProfile(false);
        }
    }

    const copyToClipboard = (copyText) => {
        setCopyAddress('Copied!');
        navigator.clipboard.writeText(copyText);
        setTimeout(function () {
            setCopyAddress('');
        }, 2000);
    }
    return (
        <div className="wagmiHolder">
            {isConnected && connector && (
                <>
                    <div className="profileHolder">

                        <button className="connectButtonName" onClick={openModalProfile} type="button">
                            {ensAvatar && <div className="imageHolder"><img src={ensAvatar} alt="avatar" /></div>}
                            {!ensAvatar && <div className="imageHolder default"><img src="https://swag.generalmagic.io/wp-content/themes/gmswagimpact/images/default-avatar.svg" alt="avatar" /></div>}

                            <div className="names">
                                <div className="name">{ensName ? ensName : `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}`}</div>
                                {ensName && <div className="subname">{address.substring(0, 4)}...{address.substring(address.length - 4, address.length)}</div>}
                            </div>
                        </button>
                        <div className="delimiter"></div>
                        <button className="hidden" onClick={requestSignAgain}>request sign</button>
                    </div>
                    {isConnected && modalProfileOpen && (
                        <div className="walletModal profileModal" onClick={closeModalProfile}>
                            <div className="walletModalContent">
                                <div className="walletModalContentInside">
                                    {ensAvatar && <div className="imageHolder"><img src={ensAvatar} alt="avatar" /></div>}
                                    {!ensAvatar && <div className="imageHolder default"><img src="https://swag.generalmagic.io/wp-content/themes/gmswagimpact/images/default-avatar.svg" alt="avatar" /></div>}
                                    <div className="names">
                                        <div className="name">
                                            <div>{ensName ? ensName : `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}`}</div>
                                            <button className="btnCopy" onClick={() => copyToClipboard(address)}>Copy Address</button><div className="btnCopyNotice">{copyAddress}</div>
                                        </div>
                                    </div>
                                    <button className="walletModalClose" onClick={closeModalProfile}>Close Modal</button>
                                    <div className="buttonsHolder">
                                        <button className="btnDisconnect" onClick={disconnectUser}>Disconnect</button>
                                    </div>
                                    {((!dataSign && !hashSignature) || (hashSignature === 'rejected' && !dataSign)) && <div className="profileSign"><button className="btnSignIn" onClick={requestSignAgain}>Sign in</button></div>}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {!isConnected && <div className="myBtnHolder"><button id="myBtn" onClick={openModal}>Connect Wallet</button></div>}

            {!isConnected && modalOpen && <div className="walletModal" onClick={closeModal}><div className="walletModalContent"><div className="walletModalContentInside"><h3>Select a Wallet to Connect</h3>{
                connectors.map((connector) => (
                    <button
                        disabled={!connector.ready}
                        key={connector.id}
                        onClick={() => connect({ connector })}
                        className={`${connector.name.replace(/\s+/g, '')} ${!connector.ready ? 'unsupported' : ''}`}
                    >
                        {connector.name}
                        {!connector.ready && ' (unsupported)'}
                        {isLoading &&
                            connector.id === pendingConnector?.id &&
                            ' (connecting)'}
                    </button>))
            }</div></div></div>}
        </div>

    )

}
